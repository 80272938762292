import React from 'react';
import {FaCheck } from "react-icons/fa";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { RiChatSmileFill } from "react-icons/ri";
import { FaSquare,FaMapLocationDot  } from "react-icons/fa6";


const Objectives = () => {
    return (
        <section id="objectives__section" className='py-5 px-5 bg-orangeSanguine w-full'>
            <div className='my-4 text-white text-center'>
                <h2 className='text-2xl lg:text-3xl my-4 font-bold'>Cap orientation</h2>
                <h4 className='text-xl lg:text-2xl my-2 font-semibold'>Un projet d'orientation se construit tout au long de la scolarité.</h4>
            </div>
            
            <div id="objectives__section__intro-description" className='w-10/12 flex justify-evenly py-3 m-auto flex-wrap'>
                <div className='bg-white py-10 px-5 my-4 lg:my-0 w-full lg:w-5/12'>
                    <h3 className='text-steelBlueDark text-xl lg:text-2xl mb-3'>Tu es frustré car :</h3>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'> <BsFillPatchQuestionFill className='mr-2 inline'/>Tu te poses plein de questions ?</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'><BsFillPatchQuestionFill className='mr-2 inline'/>Tu hésites entre des études courtes ou longues ?</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'><BsFillPatchQuestionFill className='mr-2 inline'/>Tu es indécis.e et souhaites optimiser ton orientation ?</p>
                </div>
                <div className='bg-white py-10 px-5 my-4 lg:my-0 w-full lg:w-5/12'>
                    <h3 className='text-steelBlueDark text-xl lg:text-2xl mb-3'>Tu aspires à :</h3>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'><RiChatSmileFill className='mr-2 inline'/>Découvrir  le métier de tes rêves ?</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'><RiChatSmileFill className='mr-2 inline'/>Trouver l’orientation qui te correspond le mieux ?</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2 w-10/12 md:w-full lg:w-11/12'><RiChatSmileFill className='mr-2 inline'/>Valider ton projet personnel et rassurer ton entourage en trouvant ta voie ?</p>
                </div>
            </div>
            <div className='mt-10 flex justify-center'>
                <img src="./images/cat-tigger.jpg" alt="Libérer le tigre en toi grâce à Cap Orientation" width="150px"  className='inline m-auto'/>
            </div>
            <div id="objectives__section__details-accompagnement" className='mt-2 py-5 text-center text-white w-11/12 md:w-10/12 m-auto'>
                <h2 className='text-2xl lg:text-3xl mb-6'>3 accompagnements possibles pour <span className='font-bold uppercase block'>"libérer le tigre en toi"</span></h2>
                <p className='text-base lg:text-xl my-2 w-full md:w-10/12 m-auto'>L’approche CAP ORIENTATION permet d’identifier, étape par étape, ton fonctionnement dominant et ton excellence d’action nécessaire pour trouver ta vocation et maîtriser ton choix d’orientation en t’appuyant sur le meilleur de toi-même.</p>
                <div className='w-full xl:w-10/12 bg-white text-steelBlueDark text-center my-10 py-5 px-1 lg:px-3 m-auto'>                    
                    <h4 className='text-2xl lg:text-3xl mb-3'>Les objectifs :</h4>
                    <div className='flex justify-center my-4'>
                        <FaSquare className='inline mr-4 text-orangeSanguine'/>
                        <FaSquare className='inline mr-4 text-orangeSanguine'/>
                        <FaSquare className='inline mr-4 text-orangeSanguine'/>
                    </div>
                    
                    <p className='text-onyxGray text-sm lg:text-base my-2'><FaCheck className='mr-2 inline'/>Repérer et identifier son mécanisme d’excellence en lien avec sa vocation.</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2'><FaCheck className='mr-2 inline'/>Savoir utiliser cette boussole pour définir son projet personnel d'études et de carrière.</p>
                    <p className='text-onyxGray text-sm lg:text-base my-2'><FaCheck className='mr-2 inline'/>Etablir une stratégie adaptée à son <span className='text-orangeSanguine font-bold'>« super pouvoir »</span> pour ne pas se tromper dans la recherche de la bonne filière de formation (cursus à suivre, établissement…) et tenant compte des contraintes (familiales, financières, …) et de ses capacités. </p>
                </div>
            </div>
            
        </section>
    );
};

export default Objectives;
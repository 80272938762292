import React from 'react';
import {TbTarget ,TbTargetArrow } from "react-icons/tb";

const Parcours = () => {
    return (
        <article className='flex w-full 2xl:w-11/12 flex-col md:flex-row flex-wrap xl:flex-nowrap md:justify-evenly'>
            <div className='w-11/12 md:w-5/12 xl:w-4/12 2xl:w-3/12 bg-steelBlueDark text-white mx-2 px-4 pt-4 pb-8 my-4 xl:my-0 md:h-60 xl:h-80'>
                <h4 className='text-center text-lg md:text-base lg:text-lg 2xl:text-xl my-4 border-4 border-double border-white py-2 px-2 font-bold'><TbTargetArrow className='inline mr-1'/>Avant Inscription<TbTargetArrow className='inline ml-1'/></h4>
                <p className='my-2 text-sm lg:text-base'><TbTarget className='inline mr-2' />Entretien parents-enfant gratuit et sans engagement : analyse des besoins et des attentes.</p>
            </div>

            <div div className='w-11/12 md:w-5/12 xl:w-4/12 2xl:w-3/12 bg-orangeSanguine text-white mx-2 px-4 pt-4 pb-8 my-4 xl:my-0 md:h-60 xl:h-80'>
                <div className='my-4 '>
                    <h4 className='text-center text-lg md:text-base lg:text-lg 2xl:text-xl border-4 border-double border-white py-2 px-2 font-bold'><TbTargetArrow className='inline mr-1'/>A l’inscription<TbTargetArrow className='inline ml-1'/></h4>
                    <h5 className='italic text-sm lg:text-base text-center'>Formalisation administrative</h5>
                </div>
                <p className='my-2 text-sm lg:text-base'><TbTarget className='inline mr-2' />Présentation des outils pédagogiques et mise en place du planning et des objectifs par séance.</p>
            </div>

            <div div className='w-11/12 md:w-5/12 xl:w-4/12 2xl:w-3/12 bg-steelBlueDark text-white mx-2 px-4 pt-4 pb-8 my-4 xl:my-0 md:h-60 xl:h-80'>
                <h4 className='text-center text-lg md:text-base lg:text-lg 2xl:text-xl my-4 border-4 border-double border-white py-2 px-2 font-bold'><TbTargetArrow className='inline mr-1'/>Durant l’accompagnement<TbTargetArrow className='inline ml-1'/></h4>
                <p className='my-2 text-sm lg:text-base'><TbTarget className='inline mr-2' />Restitution permanente, tissage des liens et construction du projet d’orientation.</p>
                <p className='my-2 text-sm lg:text-base font-bold text-orangeSanguine'><TbTarget className='inline mr-2 text-white' />A la fin de l’accompagnement, tu vas te projeter avec succès et sérénité, dans ton projet vocationnel.</p>
            </div>

            <div div className='w-11/12 md:w-5/12 xl:w-4/12 2xl:w-3/12 bg-orangeSanguine text-white mx-2 px-4 pt-4 pb-8 my-4 xl:my-0 md:h-60 xl:h-80'>
               <h4 className='text-center text-lg md:text-base lg:text-lg 2xl:text-xl my-4 border-4 border-double border-white py-2 px-2 font-bold'><TbTargetArrow className='inline mr-1'/>Post Accompagnement<TbTargetArrow className='inline ml-1'/></h4>
               <p className='my-2 text-sm lg:text-base'><TbTarget className='inline mr-2' />Suivi du dossier Parcoursup.</p>
               <p className='my-2 text-sm lg:text-base'><TbTarget className='inline mr-2' />Aide à la décision sur les choix d’orientation finaux.</p>
            </div>
        </article>
    );
};

export default Parcours;
import React from 'react';
import teamPresentations from "../../datas/teamPresentations.js";
import Presentation from "../Presentation";

const TeamPresentations = () => {
    return (
        <section id="team_presentation__section" className='bg-orangeSanguine py-5 px-5 w-full'>
            <h2 className='text-2xl lg:text-3xl my-4 text-white text-center font-bold'>A propos</h2>
            {/* <div id="team_presentation__section__intro-description" className='text-white'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem atque fuga itaque tempora? Voluptates dolores velit sapiente ipsam suscipit voluptatibus ut expedita recusandae maxime fugit!
            </div> */}
            <div id="team_presentation__section__offres" className='flex flex-row justify-center py-3'>
                {teamPresentations.map((teamMember)=>{
                    return <Presentation key={teamMember.id}  teamMember={teamMember} />
                })}
            </div>
        </section>
    );
};

export default TeamPresentations;
import React from 'react';
import Contact from '../Contact';
import ContactModale from '../ContactModale';

const Hero = () => {
    return (
        <section id="hero__section" className='w-full h-[60rem] xl:h-[40rem] bg-hero bg-cover bg-bottom py-5 px-3 opacity-80 flex justify-center  flex-col xl:flex-row'>
            <div className='w-full xl:w-8/12 flex items-start px-3 py-2 flex-col justify-between relative top-[2%] md:top-[3%] lg:top-0'>
                <div className='uppercase text-white tracking-widest w-full md:w-8/12 2xl:w-5/12 font-bold leading-10 backdrop-blur-md pl-5 py-5'>
                    <h1 className='text-xl lg:text-4xl my-3 w-5/5'>PROGRAMME CAP ORIENTATION JEUNES</h1>
                    <h2 className='text-lg lg:text-3xl w-full'>- Libérer le tigre en toi -</h2>
                </div>
                <div className='mt-3 mb-0 xl:mb-32 w-full md:w-9/12 lg:w-8/12 m-auto'>
                    <p className='text-orangeSanguine text-lg md:text-2xl lg:text-3xl backdrop-blur-xl orangeTextShadow text-center uppercase p-5 font-semibold'>Saviez-vous que vous êtes doués d’une excellence d’action inconsciente, unique et singulière ?</p>
                </div>
            </div> 
            <div className='w-full md:w-5/12 2xl:w-3/12'>
                <div className='hidden lg:block bg-white bg-opacity-80 relative lg:right-[-141%] xl:right-[7%] 2xl:right-[-15%]'>
                    <Contact />
                </div>
                <div className='h-[40rem] lg:hidden block'>
                    <ContactModale />
                </div>
                
            </div>
        </section>
    );
};

export default Hero;
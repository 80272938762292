import React from 'react';
import { HiChevronDoubleUp } from "react-icons/hi";

const ScrollButton = () => {
    return (
        <div id="scroll-to-top" className='bg-onyxGray w-10 h-10 fixed bottom-[5px] right-[35px] lg:right-[5px] flex justify-center items-center'>
            <a href="#hautPage" className=''><HiChevronDoubleUp className='text-white font-bold text-2xl' /></a>
        </div>
    );
};

export default ScrollButton;
import React, {useState, useEffect} from 'react';
import { IoIosInformationCircle } from "react-icons/io";
import emailjs from 'emailjs-com';

const Contact = () => {

    const [formSubmited, setFormSubmited]= useState(false)
    const [formSumitedMessage, setFormSubmitedMessage] = useState('')
    const url = "https://mo2i-back.onrender.com/message/";
    // const url = "http://localhost:5000/message/";
    // Votre mail a bien été soumis! Nous vous recontacterons.
    // Une erreur est survenue, veuillez nous contacter par téléphone ou par mail pour toute demande de contact. 

    const [formData, setFormData] = useState({
        lastname: '',
        email: '',
        phone: '',
        title: '',
       content: '',
    });

    const [errors, setErrors] = useState({
        lastname: '',
        email: '',
        phone: '',
        title: '',
        content: '',
    });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData,[name]: value,
        });
        setErrors({...errors,[name]: ''});
      };
    
      const handleSubmit = async(e) => {
        e.preventDefault();
        // Ici, vous pouvez ajouter la logique pour envoyer les données au serveur en utilisant la méthode POST
        formData.phone = (formData.phone).replace(/\s/g,"");
        submitMessage()
    };

    const validateEmail = (email) => {
        // Utiliser une expression régulière simple pour valider l'e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    
      const validatePhone = (phone) => {
        // Utiliser une expression régulière simple pour valider le numéro de téléphone
        const regex = /^(\+\d{10,12}|\d{10,12})$/;
        return regex.test(phone.trim());
      };

    const submitMessage = async() =>{
          // Validation des champs
        const newErrors = {
            lastname: formData.lastname.trim() === '' ? 'Le nom est requis.' : '',
            email:
            formData.email.trim() === ''
                ? 'L\'email est requis.'
                : !validateEmail(formData.email)
                ? 'Veuillez entrer une adresse email valide.'
                : '',
            phone:
            formData.phone.trim() === ''
                ? 'Le numéro de téléphone est requis.'
                : !validatePhone(formData.phone)
                ? 'Veuillez entrer un numéro de téléphone valide.'
                : '',
            title: formData.title.trim() === '' ? 'Le titre du message est requis.' : '',
            content:
            formData.content.trim() === ''
                ? 'Le contenu du message est requis.'
                : formData.content.length < 5
                ? 'Le contenu du message doit contenir au moins 5 caractères.'
                : '',
        };
  
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => error === '')) {

            emailjs.send('service_gpll6mc', 'template_c24jo38', formData, 'iylqmK_UNYVynRHkQ')
            .then((response) => {
              console.log('E-mail envoyé avec succès:', response);
              setFormSubmitedMessage("Votre mail a bien été soumis! Nous vous recontacterons.")
              setFormSubmited(true)
 
            })
            .catch((error) => {
              console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
              setFormSubmitedMessage("Une erreur est survenue lors de l'envoi du formulaire, veuillez nous contacter par téléphone ou par mail pour toute demande. Nous nous excusons pour la gène occasionnée.")
              setFormSubmited(true)
            });
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({...formData}) 
                })
                .then((response) => {
                    // if(response.status >= 200 ||response.status < 300) setFormSubmitedMessage("Votre mail a bien été soumis! Nous vous recontacterons.")
                    // else if(response.status >= 400) {
                    //     setFormSubmitedMessage("Une erreur est survenue lors de l'envoi du formulaire, veuillez nous contacter par téléphone ou par mail pour toute demande. Nous nous excusons pour la gène occasionnée.")
                    // }
                    // else {
                    //     setFormSubmitedMessage("Une erreur est survenue lors de l'envoi du formulaire, veuillez nous contacter par téléphone ou par mail pour toute demande. Nous nous excusons pour la gène occasionnée.")
                    // }
                    // setFormSubmited(true)
                })
                .catch((err) => {
                    console.log(err.message)
                    // setFormSubmitedMessage("Une erreur est survenue lors de l'envoi du formulaire, veuillez nous contacter par téléphone ou par mail pour toute demande. Nous nous excusons pour la gène occasionnée.")
                })
        }
    }

    useEffect(()=>{
       
    },[formSubmited])
    
    return (
        <article className='py-5 h-full flex flex-col lg:justify-center w-11/12 m-auto relative md:items-end'>
            {
                formSubmited ? 
                <h2 className='text-orangeSanguine tracking-widest text-xl font-bold text-center'>{formSumitedMessage}</h2>
                :
                <>
                    <h2 className='text-orangeSanguine tracking-widest text-xl font-bold text-center w-11/12 md:w-10/12 m-auto'>Vous avez des questions ? Contactez-nous</h2>
                    <form onSubmit={handleSubmit} className='m-auto my-2 flex flex-col w-full'>
                        <div className='w-full flex flex-row my-2 mx-1'>
                            <label 
                                className='text-yinmnBlue font-bold w-4/12 md:w-3/12'
                                title="Champ obligatoire pour envoyer le formulaire de contact"
                            >
                                Nom* :
                            </label>
                            <input
                                className='outline-none border-b border-yinmnBlue w-8/12 md:w-9/12 pl-1 tracking-wide'
                                type="text"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleInputChange}
                                required
                                placeholder='Votre nom'
                            />
                        </div>
                        <span className="error mx-1 text-red-700 text-xs block">{errors.lastname}</span>
                        <div className='w-full flex flex-row my-2 mx-1'>
                            <label 
                                className='text-yinmnBlue font-bold w-4/12 md:w-3/12'
                                title="Champ obligatoire pour envoyer le formulaire de contact"
                            >
                                Email* :
                            </label>
                            <input
                                className='outline-none border-b border-yinmnBlue w-8/12 md:w-9/12 pl-1 tracking-wide'
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                placeholder='email@gmail.com'
                            />
                        </div>
                        <span className="error mx-1 text-red-700 text-xs block">{errors.email}</span>

                        
                        <div className='w-full flex flex-row my-2 mx-1'>
                            <label 
                                className='text-yinmnBlue font-bold w-4/12 md:w-3/12'
                                title="Champ obligatoire pour envoyer le formulaire de contact"
                            >
                                Téléphone* :
                            </label>
                            <input
                                className='outline-none border-b border-yinmnBlue w-8/12 md:w-9/12 pl-1 tracking-wide'
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                                placeholder='01 01 01 01 01'
                            />
                        </div>
                        <span className="error mx-1 text-red-700 text-xs block">{errors.phone}</span>

                        <div className='w-full flex flex-row my-2 mx-1'>
                            <label 
                                className='text-yinmnBlue font-bold w-4/12 md:w-3/12'
                                title="Champ obligatoire pour envoyer le formulaire de contact"
                            >Sujet du message* :</label>
                            <input
                                className='outline-none border-b border-yinmnBlue w-8/12 md:w-9/12 pl-1 tracking-wide'
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                required
                                placeholder='Sujet de votre demande'
                            />
                        </div>
                        <span className="error mx-1 text-red-700 text-xs block">{errors.title}</span>

                        <div className='w-full flex flex-col my-2 mx-1'>
                            <label 
                                className='my-2 text-yinmnBlue font-bold w-8/12 md:w-6/12 lg:w-8/12'
                                title="Champ obligatoire pour envoyer le formulaire de contact"
                            >Contenu de votre message* : </label>
                            <textarea
                                className='outline-none border-b border-yinmnBlue  w-full pl-1 tracking-wide'
                                name="content"
                                value={formData.content}
                                onChange={handleInputChange}
                                required
                                rows="5"
                            />
                        </div>
                        <span className="error mx-1 text-red-700 text-xs block">{errors.content}</span>

                        <button className='my-2 p-2 bg-orangeSanguine tracking-widest text-lg font-bold text-yinmnBlue hover:scale-105' type="submit">Envoyer</button>
                    </form>
                </>
            }
            <div className='absolute text-xs bottom-[1%] right-[1%] group'>
                <IoIosInformationCircle className='text-orangeSanguine font-bold text-xl absolute left-[5%] bottom-[5%] cursor-pointer'/> 
                <div className=' bg-white opacity-85 hidden group-hover:block relative p-4'>
                    <p className='py-1'>En soumettant ce formulaire, vous consentez à ce que les informations de contact que vous fournissez soient utilisées dans le seul but de vous recontacter concernant les informations relatives à notre offre.
                    </p>
                    <p className='py-1'>
                    Nous utilisons ces informations uniquement à des fins internes, dans le cadre de notre engagement à vous fournir des informations pertinentes et personnalisées sur nos offres. Vos données ne seront pas vendues, partagées ou louées à des tiers.
                    </p>
                    <p className='py-1'>Si vous avez des questions concernant notre politique de confidentialité ou si vous souhaitez modifier ou supprimer vos informations de contact de notre base de données, veuillez nous contacter à l'adresse suivante : <a className='underline text-blue-600' href="mailto:k.hamdani@mo2i.team">k.hamdani@mo2i.team</a>.</p>
                </div>
            </div>
        </article>
    );
};

export default Contact;
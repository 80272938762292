import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import OffersPresentation from '../components/OffersPresentation';
import Footer from '../components/Footer';
import TeamPresentations from '../components/TeamPresentations';
import Objectives from '../components/Objectives';
import ScrollButton from '../components/ScrollButton';
import KeysDates from '../components/KeysDates';
import FlashMessage from '../components/FlashMessage';
import QuestionsReponses from '../components/QuestionsReponses';
import MessageInfo from '../components/MessageInfo';

const HomePage = () => {
    return (
        <div>
            <Header/>
            <MessageInfo />
            <Hero/>
            <Objectives />
            <KeysDates/>
            <OffersPresentation/>
            <TeamPresentations/>
            <QuestionsReponses/>
            <Footer/>
            <ScrollButton/>
            {/* <FlashMessage/> */}
        </div>
    );
};

export default HomePage;
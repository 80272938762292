import React, { useState } from 'react';
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from 'react-icons/md'

const Responses = ({questionResponses}) => {
    const [showResponse, setShowResponse] = useState(false)

    return (
        <article className='my-5 m-auto w-11/12 md:w-10/12 xl:w-6/12 rounded-2xl bg-orangeSanguine standardShadow'>
            <div className='flex flex-row justify-between px-4 py-5 w-full'>
                <h4 className='text-white text-base lg:text-l border-b-2'>{questionResponses.question}</h4>
                <button className='text-white text-3xl ml-4' onClick={()=>{
                    setShowResponse(!showResponse)}}>
                        {showResponse ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                </button>
            </div>
            
            { showResponse && 
                <div className='px-4 pb-5'>
                    {questionResponses.reponses.map((response, index)=>(
                        <p key={index} className='text-white text-sm lg:text-base mb-2'>{response}</p>
                    ))}
                </div>
            }
        </article>
    );
};

export default Responses;
import React, {useEffect} from 'react';
import { FaPhoneAlt,FaRegClock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SlBadge } from "react-icons/sl";

import Navbar from '../Navbar';

const Header = () => {

    const amorceDb = async() => {
        const url = "https://mo2i-back.onrender.com/message/getNumMessage";
        // const url = "http://localhost:5000/message/getNumMessage";

        try{
            const response = await fetch(url)
            console.log(response)
        }catch(err){
            console.log(err)
            console.log(err.message)
        }
    
    }
    
    useEffect(()=>{
        amorceDb()
    },[])
    
    return (
        <header className='w-full' id="hautPage">
            <div id="header__general_infos-hero" className='flex flex-row justify-between lg:justify-center mb-3 items-center w-11/12 m-auto'>
                <div className='w-1/6 flex justify-start xl:justify-end'> 
                    <img src="./images/MO2I-institutaction.png" alt="Logo illustrant de la page MO2I Cap orientation" width="150" />
                </div>
                <div className='w-5/6 xl:w-4/6 flex flex-col lg:flex-row justify-end items-end lg:items-start text-sm mt-10'>
                    <div className='flex lg:justify-items-center'>
                        <div className='flex flex-col text-right'>
                            <h3 className='text-steelBlueDark font-bold'><SlBadge  className='text-orangeSanguine inline mr-2 font-bold'/>Katia Hamdani</h3>
                            <ul className='list-none text-s text-steelBlueDark'>
                                <li>Coach de Modus Operandi International Institute</li>
                                <li>Orientation des jeunes</li>
                                <li>Bilan de compétences adultes</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='flex place-content-end lg:justify-items-center  w-3/4 py-1 md:py-0'>
                        <FaPhoneAlt  className='text-orangeSanguine mr-2 mt-1'/>
                        <a href="tel:+33634602330" className='text-steelBlueDark'>(+33) 6 34 60 23 30</a>
                    </div>
                    <div className='flex place-content-end lg:justify-items-center w-3/4 py-1 md:py-0'>
                        <MdEmail className='text-orangeSanguine mr-2 mt-1'/>
                        <a className='text-steelBlueDark hover:underline' href="mailto:k.hamdani@mo2i.team">k.hamdani@mo2i.team</a>
                        
                    </div>
                    <div className='flex place-content-end lg:justify-items-center w-3/4 py-1 md:py-0'>
                        <FaRegClock  className='text-orangeSanguine mr-2 mt-1'/>
                        <p className='text-steelBlueDark'>Disponible de 9h00 - 19h00</p>
                    </div> */}
                </div>
            </div>

            <div id="header__general_infos-navbar" className='w-full py-10 bg-steelBlueDark text-white'>
                <Navbar />
            </div>
            
        </header>
    );
};

export default Header;
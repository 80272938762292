const questionsReponses = [
    {
        id:1,
        question:"C’est quoi Parcoursup ?",
        reponses: ["Parcoursup est la plateforme nationale d’admission en enseignement supérieur en France. Elle a été créée pour simplifier et centraliser le processus d’inscription et de sélection des candidats aux formations de l’enseignement supérieur.","Parcoursup permet aux élèves de terminale et aux étudiants en réorientation de formuler leurs vœux pour les formations de leur choix et de recevoir des propositions d’admission en fonction des critères d’admission de chaque établissement.","Les candidats peuvent également y trouver des informations sur les différentes formations, les critères de sélection, les débouchés professionnels, et bien plus encore"],
    },
    {
        id:2,
        question:"Comment reçoit-on les réponses de la part des formations ?",
        reponses: ["Les élèves sont prévenus de 3 façons :",["Via l’application Parcoursup (à télécharger sur son portable)","Par mail, à l’adresse mail valide exigée au début de l’inscription sur Parcoursup","Via la messagerie intégrée à la plateforme Parcoursup"]],
    },
    {
        id:3,
        question:"Quels sont les critères d’admission sur Parcoursup ?",
        reponses: ["Ils varient d’une formation à l’autre. Ils peuvent inclure le dossier scolaire (contrôle continu), des lettres de motivation, des entretiens, des tests, etc. Chaque formation précise ses propres critères sur la plateforme Parcoursup.","Pensez bien à remplir la rubrique « Activités et centres d’intérêt », c’est une rubrique qui vous aide à vous démarquer."],
    },
    {
        id:4,
        question:"Quelle est la différence entre une formation sélective et non sélective sur Parcoursup ?",
        reponses: ["Les formations sélectives ont un nombre limité de places et utilisent des critères spécifiques pour choisir les étudiants.","Les formations non sélectives sont accessibles à tous les candidats qui remplissent les conditions minimales requises.","Mais attention : même dans les formations non sélectives, il peut y avoir plus de candidats que de places disponibles."],
    },
    {
        id:5,
        question:"Quand dois-je répondre aux propositions d’admission sur Parcoursup ?",
        reponses: ["Vous devez répondre aux propositions d’admission dans le délai indiqué sur Parcoursup. En général, les candidats ont quelques jours pour accepter ou refuser une proposition. Il est important de respecter ces délais pour garantir votre place dans la formation de votre choix. Si vous ne répondez pas dans les délais, votre proposition pourrait être annulée."],
    },
    {
        id:6,
        question:"Que faire si je suis sur une liste d’attente pour une formation ?",
        reponses: ["Si vous êtes sur liste d’attente, il est important de rester informé et de surveiller régulièrement vos réponses sur Parcoursup.","Si une place se libère dans la formation, vous pouvez être contacté pour l’accepter.","En attendant, vous pouvez également accepter une autre proposition d’admission si vous le souhaitez, sans perdre votre place sur la liste d’attente."],
    },
    {
        id:7,
        question:"Comment optimiser mon admission dans les grandes écoles ?",
        reponses: ["Vous devez passer des épreuves des concours pour intégrer plus facilement certaines  formations sélectionnées sur Parcoursup.","Les épreuves sont  conçues pour sélectionner des profils d’étudiants en adéquation avec ceux que recherchent les écoles.","La nature de ces épreuves exige donc de votre part de vous y familiariser et de vous y entraîner le plus tôt possible pour être prêt le jour des épreuves.","Contrairement au bac qui est un examen, les concours sont des concours, ce qui signifie que vous devez obtenir les meilleurs résultats possibles pour intégrer l’une de ses grandes écoles.","Il existe des plateformes dédiées spécifiquement à l’entraînement pour vous permettre de vous entraîner en amont des concours.","Les concours sont payants et se déroulent à des dates définies au préalable."]
    }
]

export default questionsReponses;
import React from 'react';
import { FaCalendarAlt } from "react-icons/fa";
import {TbTargetArrow } from "react-icons/tb";

const KeysDates = () => {
    return (
        <section id="keys_datas__section" className='bg-steelBlueDark py-5 px-5 w-full'>
        <h2 className='text-2xl lg:text-3xl my-4 text-white text-center font-bold'><TbTargetArrow className='inline mr-2'/>Les dates clés à retenir<TbTargetArrow className='inline ml-2'/></h2>
        <div id="team_presentation__section__intro-description" className='text-white py-5 flex flex-col items-center'>
            <ul className='list-none w-11/12 xl:w-6/12 text-sm lg:text-base'>
                <li className='my-4'><FaCalendarAlt className='inline mr-2'/><span className='mr-4 font-semibold border-b-2'>20 Décembre 2023 :</span>ouverture du site d’information <a href="https://www.parcoursup.gouv.fr/" target="_blank" className='underline font-semibold' title='https://www.parcoursup.gouv.fr/'> parcoursup.fr</a> pour consulter l’offre de formation</li>
                <li className='my-4'><FaCalendarAlt className='inline mr-2'/><span className='mr-4 font-semibold border-b-2'>17 Janvier 2024 :</span>début des inscriptions et de la formulation des vœux ( 10 vœux maximum)</li>
                <li className='my-4'><FaCalendarAlt className='inline mr-2'/><span className='mr-4 font-semibold border-b-2'>14 Mars 2024 :</span>date limite de dépôt des v&oelig;ux</li>
                <li className='my-4'><FaCalendarAlt className='inline mr-2'/><span className='mr-4 font-semibold border-b-2'>3 avril 2024 :</span>date limite pour compléter son dossier et confirmer ses v&oelig;ux</li>
                <li className='my-4'><FaCalendarAlt className='inline mr-2'/><span className='mr-4 font-semibold border-b-2'>12 juillet 2024 :</span>fin de la phase principale d’admission</li>
            </ul>
        </div>
    </section>
    );
};

export default KeysDates;
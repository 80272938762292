const teamPresentations = [
    {
        id:1,
        name:"Katia",
        lastname: "Hamdani",
        description:[
            ["Mes atouts", ["Experte en identification des processus d’action, je suis passionnée par les neurosciences, l’approche systémique et l’approche narrative.","J'ai créé des outils éprouvés pour accompagner les personnes à faire les bons choix d'orientation professionnelle." ], ],
            ["Mon parcours", ["J'ai accompagné des centaines de personnes et d'entreprises dans leurs processus décisionnels dans un contexte en permanente évolution."]],
            ["Mon principal enjeu", ["Donner à l’autre la chance de s’accomplir avec succès."]],
            ["Mes valeurs",["La performance, l’autonomie et l’harmonie."]],
        ],
        partenaires: [["Profil C", "https://www.profil-c.com/"], ["L’institut de l’action", "https://mo2i.net/"]],
        certifications: ['Coach de Modus Operandi International Institute','Orientation des jeunes ','Bilan de compétences adultes'],
        phone:"+33 6 34 60 23 30",
        mail:"k.hamdani@mo2i.team",
        linkedin:"https://fr.linkedin.com/in/hkatia-hamdani",
        photo:"/images/teamMemberKatia.png",
    }
]

export default teamPresentations;
import React, { useState } from 'react';
import Contact from '../Contact';
import { FaChevronLeft ,FaChevronRight} from "react-icons/fa";

const ContactModale = () => {
    const [openModal, setOpenModal] = useState(false)

    const openSidebar = () =>{
        setOpenModal(!openModal)
    }
    return (
        <>
            <div className='w-6/12 text-right flex flex-row justify-end relative bottom-[-16px] z-10 right-[-171px] sm:right-[-587px] bg-white bg-opacity-80 '>
                <p className='text-orangeSanguine px-2'>Formulaire de Contact</p>
                <button className='sidebar-formContact-toggle lg:hidden bg-orangeSanguine' onClick={openSidebar}>
                    {openModal ? <FaChevronRight className='inline text-white'/>: <FaChevronLeft className='inline text-white' /> }
                </button>
                
            </div>
            {
                openModal &&
                    <div className='bg-white bg-opacity-80 relative right-[2%] bottom-[-3%] sm:w-[70vw]'>
                        <Contact />
                    </div>
            }
        </>
        
    );
};

export default ContactModale;
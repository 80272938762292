import React from 'react';
import { FaLinkedin ,FaWhatsappSquare, FaPhoneAlt,FaRegClock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
const Footer = () => {

    const menuCategorie = [["Cap Orientation", "#objectives__section"], ["Modalités & Tarifs" ,"#offer_presentation__section"],["A propos","#team_presentation__section"]]
    return (
        <footer className='w-full'>
            <div className="bg-steelBlueDark py-5 ">
                <div className='w-11/12 mx-auto md:w-11/12 flex justify-between text-white'>
                    <div className='w-4/12'>
                        <div className='mb-2'>
                            <h4 className='text-sm lg:text-base font-bold mb-2'>Menu</h4>
                            <ul className='list-none text-xs lg:text-sm text-white'>
                                {menuCategorie.map((category)=>{
                                    return <li key={category[0]} className='hover:text-orangeSanguine hover:underline'>
                                    <a href={category[1]}>{category[0]}</a></li>
                                })} 
                            </ul>
                        </div>
                    </div>
                    <div className='w-2/12 md:w-3/12 flex items-center'>
                        <img src="./images/MO2I-institutaction.png" alt="MO2I-institut de l'action"  width="175px"/>
                    </div>
                    <div className='w-5/12 md:w-4/12 '>
                        {/* <h4 className='text-xs lg:text-sm font-bold text-right lg:text-left mb-2'>Notre équipe pédagogique</h4> */}
                        <div className='flex flex-col md:flex-row text-lightGray hover:text-orangeSanguine text-xs lg:text-sm'>
                            <div className='w-full md:w-4/12'>
                                <p className='pb-1'>Katia Hamdani</p>
                            </div>
                            <div className='flex flex-col w-full md:w-10/12 text-xs'>
                                <div className='flex w-2/12 flex-row mb-1 h-6 items-center'>
                                    <a href="https://fr.linkedin.com/in/hkatia-hamdani" target='_blank'><FaLinkedin className="text-lightGray hover:text-orangeSanguine mr-1 h-6 w-6"/></a>
                                    <a href="https://wa.me/33634602330" target='_blank'><FaWhatsappSquare className="text-lightGray hover:text-orangeSanguine h-6 w-6"/></a>
                                </div>
                                <div className='flex flex-wrap mb-1 items-center w-full'>
                                    <FaPhoneAlt  className='text-lightGray text-xs mr-2 h-4 w-4'/>
                                    <a href="tel:+33634602330" className='text-lightGray hover:text-orangeSanguine text-xs'>(+33) 6 34 60 23 30</a>
                                </div>
                                <div className='flex flex-wrap mb-1 items-center'>
                                    <MdEmail className='text-lightGray text-xs mr-2 h-4 w-4'/>
                                    <a className='text-lightGray hover:text-orangeSanguine text-xs' href="mailto:k.hamdani@mo2i.team">k.hamdani@mo2i.team</a>
                                    
                                </div>
                                <div className='flex flex-wrap mb-1 items-center'>
                                    <FaRegClock  className='text-lightGray text-xs mr-2 h-4 w-4'/>
                                    <p className='text-lightGray hover:text-orangeSanguine text-xs'>Disponible de 9h00 - 19h00</p>
                                </div>
                            </div>
                            
                        </div>
                        {/* <div className='flex text-lightGray hover:text-orangeSanguine items-center'>
                            <p className='mr-2'>Second Intervenant</p>
                            <div className='w-10 flex justify-between'>
                                <a href="http://www.linkedin.com/in/katia-hamdani" target='_blank'><FaLinkedin className="text-lightGray hover:text-orangeSanguine"/></a>
                                <a tel="0770088332"><FaWhatsappSquare className="text-lightGray hover:text-orangeSanguine"/></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className='flex text-xs flex-col items-center bg-white text-onyxGray py-2'>
                <p className=''>Copyright © 2024</p>
                <p>- Site développé par <a href="https://www.linkedin.com/in/adeline-lucas-web-dev/" target="_blank">Adeline LUCAS</a> -</p>
            </div>   
        </footer>
    );
};

export default Footer;
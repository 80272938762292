import React from 'react';
import { FaCalendarAlt } from "react-icons/fa";

const MessageInfo = () => {
    return (
        <section className='py-6 flex flex-col md:flex-row items-start w-10/12 m-auto flex-wrap'>
            <div className='flex w-5/12 md:w-4/12 justify-start xl:justify-end h-fit'>
                <h4 className='text-orangeSanguine font-bold text-lg lg:text-xl text-right mr-4'><FaCalendarAlt className='relative mr-2 inline bottom-[5px]'/>Actualité : </h4>
            </div>
            <p className="text-orangeSanguine font-bold text-base lg:text-lg text-left">Vous avez encore jusqu'au 14 mars 2024 pour exprimer vos v&oelig;ux.</p>
        </section>   
    );
};

export default MessageInfo;
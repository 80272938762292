import React, {useEffect, useState} from 'react';
import { FaRegPlusSquare,FaCheck } from "react-icons/fa";
import { FaRegSquareMinus, FaMapLocationDot, FaMapLocation  } from "react-icons/fa6";
import {TbTargetArrow } from "react-icons/tb";
import { GrMap } from "react-icons/gr";

const Offer = ({offer}) => {
    const [showInfo, setShowInfo] = useState(false)
    const [offerImg, setOfferImg] = useState(<FaMapLocationDot/>)
    // Récupération de l'image
    const offersImgArray = [
        <GrMap className='text-3xl inline mt-5 text-white' />,
        <FaMapLocation className='text-3xl inline mt-5 text-white'/>,
        <FaMapLocationDot className='text-3xl inline mt-5 text-white'/>
    ]

    useEffect(()=>{
        setOfferImg(offersImgArray[offer.id -1])
    }, [])

    return (
        <div className=''>
            <article className='bg-steelBlueDark w-[82vw] md:w-[18rem] xl:w-[22rem] 2xl:w-[30rem] offerShadow md:mx-2'>
                <header className='flex flex-col items-center my-2 h-64 justify-between'>
                    {offerImg}
                    <h4 className="text-white text-base lg:text-lg hover:underline text-center">{offer.name}</h4>
                    <div className='flex flex-col items-center w-10/12'>
                        {
                            offer.resume.map((resume, index)=>(
                                <p key={index} className='text-lightGray text-sm mb-1'>{resume}</p>
                            ))
                        }
                    </div>
                    
                    <button className='text-white text-xl my-4' onClick={()=>{
                    setShowInfo(!showInfo)}}>
                        {showInfo ? <FaRegSquareMinus/> : <FaRegPlusSquare />}
                    </button>
                </header>
            </article>
            {showInfo && 
                <div className='bg-steelBlueDark w-[82vw] md:w-[18rem] xl:w-[22rem] 2xl:w-[30rem] offerShadowLight md:mx-2'>   
                    <div className='px-3 py-2 '>
                        {
                            offer.details.map((detail, index)=>{
                                return( 
                                    <div key={index} className='py-3 ' >
                                        
                                        <h4 className='text-orangeSanguine text-base tracking-wider text-left my-2 font-semibold'>
                                            <TbTargetArrow className='mr-2 inline'/>{detail[0]}
                                        </h4>
                                        {detail[1].map((info, i)=>(
                                            <p  key={i} className="text-white text-sm my-1"><FaCheck  className='mr-2 inline'/>{info}</p>
                                        ))}
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                    <div className='bg-orangeSanguine flex flex-col items-center '>
                        <p className='py-1 text-steelBlueDark font-bold w-full px-2'><span className=' text-white mr-1 tracking-wide'>{offer.offerHours}</span>heures</p>
                        {offer.personnalWork && 
                            <p className='py-1 text-steelBlueDark font-bold w-full px-2'><span className=' text-white mr-1 tracking-wide'>+ {offer.personnalWork}</span>heures de travail personnel</p>
                        }
                        <p className='py-1 text-steelBlueDark font-bold w-full px-2'><span className=' text-white mr-1 tracking-wide'>{offer.numberSeance}</span>séances</p>
                        <p className='py-1 text-steelBlueDark font-bold w-full px-2'><span className=' text-white tracking-wide mr-1'>{offer.price} €</span>ttc</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default Offer;
import React from 'react';
import {TbTarget ,TbTargetArrow } from "react-icons/tb";

const Navbar = () => {
    return (
        <ul className='text-sm lg:text-base tracking-wider flex justify-evenly'>
            <li className='hover:underline hover:scale-105 w-3/12  md:w-4/12 text-center'>
                <a href="#objectives__section" className='' ><TbTargetArrow className='inline mr-2'/>Cap orientation</a>
            </li>
            <li className='hover:underline hover:scale-105 w-4/12 text-center'>
                <a href="#offer_presentation__section" className='' ><TbTargetArrow className='inline mr-2'/>Modalités</a>
            </li>
            <li className='hover:underline hover:scale-105 w-4/12 text-center'>
                <a href="#team_presentation__section" className=''><TbTargetArrow className=' inline mr-2'/>A propos</a>
            </li>
        </ul>
    );
};

export default Navbar;
import React from 'react';
import questionsReponses from '../../datas/questionsReponses';
import Responses from '../Responses';

const QuestionsReponses = () => {
    return (
        <section id="questions-reponses__section" className='py-5 px-5 w-full'>
            <h2 className='text-2xl lg:text-3xl my-4 text-steelBlueDark text-center font-bold'>Foire aux questions</h2>
            <div id="questions-reponses__section__details" className='flex flex-col justify-between py-3'>
                {questionsReponses.map((question)=>{
                    return <Responses questionResponses={question} key={question.id} />})}
            </div>
        </section>
    );
};

export default QuestionsReponses;
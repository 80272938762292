const offersDetails = [
    {
        id:3,
        name: 'Formule à Posteriori',
        resume:["Trouver  la bonne filière de formation","+ Constituer le dossier parcoursup","+ Suivre les réponses parcoursup"],
        details:[
            ["Approfondir la connaissance de soi",["Conscientiser mes traits de caractères dominants."]],
            ["Je découvre mon Super Pouvoir", ["Identifier mon excellence d’action privilégié et repérer le contexte dans lequel il se déclenche."]],
            ["Je bâtis un projet d'orientation clair", ["Construire un projet dans lequel je serai super efficace et épanoui."]],
            ["Je garde le cap et constitue mon dossier parcoursup",["Être le stratège de son orientation."] ],
            ["Je fais le point à posteriori", ["Point d'étape et ajustement à mener."]]
        ],
        offerHours: "15",
        numberSeance: "7",
        personnalWork:"3",
        price: "689"
    },
    {
        id:2,
        name: 'Formule Booster',
        resume:["Trouver  la bonne filière de formation","+ Constituer le dossier parcoursup"],
        details:[
            ["Approfondir la connaissance de soi",["Conscientiser mes traits de caractères dominants."]],
            ["Je découvre mon Super Pouvoir", ["Identifier mon excellence d’action privilégié et repérer le contexte dans lequel il se déclenche."]],
            ["Je bâtis un projet d'orientation clair", ["Construire un projet dans lequel je serai super efficace et épanoui."]],
            ["Je garde le cap et constitue mon dossier parcoursup",["Être le stratège de son orientation."] ],
        ],
        offerHours: "14",
        numberSeance: "6",
        personnalWork:"3",
        price: "589"
    },
    {
        id:1,
        name: 'Formule Action',
        resume:["Trouver  la bonne filière de formation"],
        details:[
            ["Approfondir la connaissance de soi",["Conscientiser mes traits de caractères dominants."]],
            ["Je découvre mon Super Pouvoir", ["Identifier mon excellence d’action privilégié et repérer le contexte dans lequel il se déclenche."]],
            ["Je bâtis un projet d'orientation clair", ["Construire un projet dans lequel je serai super efficace et épanoui."]],
        ], 
        offerHours: "12",
        numberSeance: "5",
        personnalWork:"3",
        price: "401"
    }
]

export default offersDetails
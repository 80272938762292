import React from 'react';
import Offer from '../Offer';
import offersDetails from "../../datas/offersDetails.js";
import Parcours from '../Parcours/index.js';

const OffersPresentation = () => {

    return (
        <section id="offer_presentation__section" className='py-5 px-5 w-full flex-wrap'>
            <h2 className='text-2xl lg:text-3xl my-4 text-steelBlueDark text-center font-bold'>Notre accompagnement Cap Orientation</h2>
            <div id="offer_presentation__section__offres" className='flex flex-row justify-center xl:m-auto xl:justify-between py-3 xl:mb-10 flex-wrap w-full xl:w-10/12 '>
                {offersDetails.map((offer)=>{
                    return <Offer key={offer.id}  offer={offer} />
                })}
            </div>
            <h2 className='text-2xl lg:text-3xl my-4 text-steelBlueDark text-center font-bold'>Modalités</h2>
            <div id="offer_presentation__section__intro-description" className='py-3 mb-6 m-auto flex justify-center'>
                < Parcours/>
            </div>
        </section>
    );
};

export default OffersPresentation;
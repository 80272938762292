import React, {useState} from 'react';
import {TbTarget ,TbTargetArrow } from "react-icons/tb";

const Presentation = ({teamMember}) => {

    return (
        <div className='w-full md:w-10/12 xl:w-8/12'>
            <article className='bg-white w-11/12 m-auto standardShadow'>
                <div className='flex flex-col items-center my-2 mx-3'>
                    <img src={teamMember.photo}  alt={`${teamMember.name}  ${teamMember.lastname}- MO2I`} width="130px" className='my-2 rounded-full' />
                    <h4 className="text-steelBlueDark font-bold hover:underline my-2 text-lg lg:text-xl">{teamMember.name} <span>{teamMember.lastname}</span></h4>
                    <div className='w-full'>
                        {
                            teamMember.description.map((info, index) =>(
                                <div className='flex flex-col my-2'>
                                    <p className='font-bold mr-2 text-steelBlueDark px-3 py-1 w-fit' key={index}>
                                        <TbTargetArrow className='inline mr-2' />{info[0]} :
                                    </p>
                                    <div className='w-full'>
                                        {info[1].map((detail, i) =>(
                                            <p key={i} className='text-sm lg:text-base ml-3'>
                                                <TbTarget className='inline mr-2' />{detail}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='w-full mb-2'>
                        <div className='flex flex-col mb-2'>
                            <p className='font-bold mb-2 mr-2 text-steelBlueDark px-3 py-1 w-fit'>
                                <TbTargetArrow className='mr-2 inline'/>Mes partenaires de l’orientation professionnelle :
                            </p>
                            <div className='w-full'>
                                {
                                teamMember.partenaires.map((partenaire, i) => (
                                    <p key={i} className='text-sm lg:text-base ml-3'>
                                        <TbTarget className='inline mr-2' />{partenaire[0]} : 
                                        <a className='underline italic text-sm lg:text-base ml-3' href={partenaire[1]} target='_blank'>{partenaire[1]}</a>
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default Presentation;